import { useState } from "react";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { useGetInfo } from "antopolis-react-utils/hooks";
import BackButton from "../../../../../Partials/Elements/BackButton/BackButton";
import { CRUDTable } from "../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ImageCell } from "../../../../../Partials/Layout/CRUD/ImageCell/ImageCell";
import { ShortTextCell } from "../../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import { Modal } from "../../../../../Partials/Elements/Modal/Modal";
import ViewBankDetails from "./ViewBankDetails";
import { Link } from "react-router-dom";

function TokenInvestors({ api }) {
  const [toggleFetch, setToggleFetch] = useState(false);
  const axiosInstance = useAxiosInstance();
  const [showBankDoc, setShowBankDoc] = useState(false);
  const [selectedBankData, setSelectedBankData] = useState(null)

  const { info: tokenInvestors } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "getAllTokenInvestors",
    toggleFetch,
  });

  const handleBankDetails = (bankData) => {
    setSelectedBankData(bankData)
    setShowBankDoc(true);
  };

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <BackButton />
          <h1 className="screen_heading">{`Nano Asset Investors (${tokenInvestors?.length} in total)`}</h1>
        </div>
        <Link to="/main/nanoAssetInvestorInvites">
          <button className="submit">Investor Invitations</button>
        </Link>
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          {/* <CRUDth th="Image" /> */}
          <CRUDth align={"left"} th="Name" />
          <CRUDth th="Email Address" />
          <CRUDth th="Bank Account" />
          {/* <CRUDth th="Level" /> */}
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {tokenInvestors &&
            tokenInvestors?.map((e) => (
              <tr key={e._id}>
                <td>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <img
                      style={{ height: "30px", width: "30px", borderRadius: "50%" }}
                      src={
                        e.image
                          ? process.env.REACT_APP_SPACES_URL + e.image
                          : `https://ui-avatars.com/api/?name=${e.name}&background=random&length=1`
                      }
                      alt=""
                    />
                    <p>{e.name}</p>
                  </div>
                </td>
                <ShortTextCell text={e.email} />
                <td className="whitespace-nowrap cursor-pointer hover:scale-105" style={{ textAlign: "center", fontSize: "16px" }} onClick={handleBankDetails} >UCB</td>
                <td className="whitespace-nowrap cursor-pointer hover:scale-105 relative" style={{ textAlign: "center", fontSize: "16px" }}>
                  <Link className="inset-0 absolute flex items-center justify-center" to={`/main/transactions/${e._id}`}>Transactions</Link>
                </td>
                {/* <SHortTextCell text={e.bank} /> */}
                {/* <ShortTextCell text={e.level} /> */}
              </tr>
            ))}
        </tbody>
      </CRUDTable>

      {
        showBankDoc && (
          <Modal
            extraClass={"small"}
            modalHeading={`View Bank Details`}
            setShowModalContent={setShowBankDoc}
          >
            <ViewBankDetails bankData={selectedBankData} />
          </Modal>
        )
      }
    </div>
  );
}

export default TokenInvestors;
