export const EMPLOYEES_API = "employeeApp/";
export const CUSTOMERS_API = "customers/";
export const MANAGE_Employees_API =
  EMPLOYEES_API + "protected/manageEmployees/";
export const MANAGE_Investors_API =
  EMPLOYEES_API + "protected/manageInvestors/";
export const MANAGE_INVESTOR_USERS_API =
  EMPLOYEES_API + "protected/manageInvestorUser/";
export const MANAGE_INVESTOR_USERS_INVESTORS_API =
  EMPLOYEES_API + "protected/manageInvestorUsersInvestor/";

export const MANAGE_Company_API = EMPLOYEES_API + "protected/manageCompanies/";

export const MANAGE_TokenInvestors_API =
  EMPLOYEES_API + "protected/manageTokenInvestors/";

export const MANAGE_Tokens_API =
  EMPLOYEES_API + "protected/manageTokens/";

export const MANAGE_TOKEN_IMAGES_API =
  EMPLOYEES_API + "protected/manageTokenImages/";

export const MANAGE_TOKEN_REQUESTS_API =
  EMPLOYEES_API + "protected/manageTokenRequest/";

export const MANAGE_INVESTMENT_DOCUMENTS_API = EMPLOYEES_API + "protected/manageInvestmentDocuments/";

export const MANAGE_ALLINVESTMENTS_API = EMPLOYEES_API + "protected/manageAllInvestments/";

export const MANAGE_STATISTICS_API = EMPLOYEES_API + "protected/manageStatistics/";