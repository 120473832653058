import { useGetInfo } from 'antopolis-react-utils/hooks';
import React, { useState } from 'react';
import { MANAGE_STATISTICS_API } from '../../../../Utilities/APIs/APIs';
import { useAxiosInstance } from '../../../../Utilities/Hooks/useAxiosInstance';
import { DiVim } from 'react-icons/di';

function Statistics() {
    const [wcInvestorFilter, setWcInvestorFilter] = useState("total");
    const [companyFilter, setCompanyFilter] = useState("total");
    const [toggleFetch, setToggleFetch] = useState(false);

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    const axiosInstance = useAxiosInstance();

    const handleWcInvestorFilterChange = (event) => {
        setWcInvestorFilter(event.target.value);
    };

    const handleCompanyFilterChange = (event) => {
        setCompanyFilter(event.target.value);
    };

    const { info } = useGetInfo({
        axiosInstance: axiosInstance,
        api: "api/" + MANAGE_STATISTICS_API + "getStatistics",
        toggleFetch,
    });

    const data = info?.data;

    const getWcInvestorCount = () => {
        if (wcInvestorFilter === "active") {
            return data?.totalActiveInvestor;
        } else if (wcInvestorFilter === "archived") {
            return data?.totalArchivedInvestor;
        } else {
            return data?.totalActiveInvestor + data?.totalArchivedInvestor;
        }
    };

    const getCompanyCount = () => {
        if (companyFilter === "active") {
            return data?.totalActiveCompanies;
        } else if (companyFilter === "archived") {
            return data?.totalArchivedCompanies;
        } else {
            return data?.totalActiveCompanies + data?.totalArchivedCompanies;
        }
    };

    return (
        <div className="h-full w-full text-white p-5">
            <div className="p-20">
                <div className="grid grid-cols-4 gap-10 mb-10">

                    {/* WC Investor with Filter */}
                    <div className="bg-[#333333] p-10 rounded-lg shadow-md relative">
                        <h2 className="font-semibold mb-10 text-5xl capitalize">{wcInvestorFilter} WC Investor</h2>
                        <div className="text-4xl">
                            {getWcInvestorCount()}
                        </div>

                        {/* Dropdown for WC Investor Filter */}
                        <div className="absolute top-5 right-5">
                            <select
                                value={wcInvestorFilter}
                                onChange={handleWcInvestorFilterChange}
                                className="bg-gray-600 text-white p-2 rounded-lg text-xl text-center"
                            >
                                <option className='bg-black' value="total">All</option>
                                <option className='bg-black' value="active">Active</option>
                                <option className='bg-black' value="archived">Archived</option>
                            </select>
                        </div>
                    </div>

                    {/* Nano Asset Investors */}
                    <div className="bg-[#333333] p-10 rounded-lg shadow-md">
                        <h2 className="font-semibold mb-10 text-5xl">Total Nano Asset Investors</h2>
                        <div className="text-4xl">{data?.totalNanoAssetInvestors}</div>
                    </div>

                    {/* Nano Assets */}
                    <div className="bg-[#333333] p-10 rounded-lg shadow-md">
                        <h2 className="font-semibold mb-10 text-5xl">Total Nano Assets</h2>
                        <div className="text-4xl">{data?.totalNanoAssetNumber}</div>
                    </div>

                    {/* Total Company with Filter */}
                    <div className="bg-[#333333] p-10 rounded-lg shadow-md relative">
                        <h2 className="font-semibold mb-10 text-5xl capitalize">{companyFilter} Company</h2>
                        <div className="text-4xl">
                            {getCompanyCount()}
                        </div>

                        {/* Dropdown for Company Filter */}
                        <div className="absolute top-5 right-5">
                            <select
                                value={companyFilter}
                                onChange={handleCompanyFilterChange}
                                className="bg-gray-600 text-white p-2 rounded-lg text-xl text-center"
                            >
                                <option className='bg-black' value="total">All</option>
                                <option className='bg-black' value="active">Active</option>
                                <option className='bg-black' value="archived">Archived</option>
                            </select>
                        </div>
                    </div>
                </div>

                {/* All Investments & All Requests Section */}
                <div className="grid grid-cols-2 gap-10">
                    <div className="bg-[#333333] p-20 rounded-lg shadow-md">
                        <h2 className="font-semibold mb-10 text-center text-5xl">All Investments</h2>
                        <div className="flex justify-between relative">
                            <div className="flex flex-col gap-4">
                                <h3 className="font-semibold text-4xl">Company</h3>
                                <p className="text-4xl">
                                    {data?.totalAmountInvested[0]?.totalAmountInvested.toLocaleString()}
                                </p>
                            </div>
                            <div className="absolute left-1/2 w-[1px] bg-white top-0 bottom-0" />
                            <div className="flex flex-col gap-4">
                                <h3 className="font-semibold text-4xl">Nano Assets</h3>
                                <p className="text-4xl">
                                    {data?.totalNanoAssetsAmount[0]?.totalNanoAssetsAmount.toLocaleString()}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="bg-[#333333] p-20 rounded-lg shadow-md">
                        <h2 className="font-semibold mb-10 text-center text-5xl">All Requests</h2>
                        <div className="flex justify-between relative">
                            <div className="flex flex-col gap-4">
                                <h3 className="font-semibold text-4xl">Pending Purchase</h3>
                                <p className="text-4xl">{data?.totalPendingBuyTokenRequests}</p>
                            </div>
                            <div className="absolute left-1/2 w-[1px] bg-white top-0 bottom-0" />
                            <div className="flex flex-col gap-4">
                                <h3 className="font-semibold text-4xl">Pending Sale</h3>
                                <p className="text-4xl">{data?.totalPendingSellTokenRequests}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Statistics;
