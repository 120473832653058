import { useContext, useEffect, useMemo, useState } from "react";
import { DataContext } from "../../../../../Contexts/GlobalContexts/DataContext";
import { AuthContext } from "../../../../../Contexts/GlobalContexts/AuthContext";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { MANAGE_TOKEN_REQUESTS_API } from "../../../../../Utilities/APIs/APIs";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../../Partials/Elements/BackButton/BackButton";
import SelectInput from "../../../../Partials/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import { CRUDTable } from "../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ShortTextCell } from "../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import { takaFormatter } from "../../../../../Utilities/HelperFunctions/takaFormatter";
import { formatDate } from "../../../../../Utilities/HelperFunctions/formateDate";
import { Modal } from "../../../../Partials/Elements/Modal/Modal";
import ViewReceipt from "../PendingRequest/ViewReceipt";


function AllRequest({ api }) {
    const [selectedReceipt, setSelectedReceipt] = useState(null);
    const [showReceiptSection, setShowReceiptSection] = useState(false);

    const { toggleFetch, triggerFetch } = useContext(DataContext);

    const [tokenRequests, setTokenRequests] = useState([]);


    const [filter, setFilter] = useState("buy");
    const [statusFilter, setStatusFilter] = useState(""); // Add status filter
    const [targetID, setTargetID] = useState(null);

    const { employee } = useContext(AuthContext);
    const axiosInstance = useAxiosInstance();

    const { info } = useGetInfo({
        axiosInstance: axiosInstance,
        api: "api/" + MANAGE_TOKEN_REQUESTS_API + "getAllRequest",
        toggleFetch,
    });

    useEffect(() => {
        info?.success && setTokenRequests(info.data);
    }, [info]);


    const navigate = useNavigate();

    const handleFilterChange = (value) => {
        setFilter(value);
    };

    const handleStatusFilterChange = (value) => {
        setStatusFilter(value);  // Handle status filter change
    };

    const handleImageClick = (img) => {
        setSelectedReceipt(img);
        setShowReceiptSection(true);
    };


    const filteredTokenRequests = useMemo(() => {
        if (!tokenRequests) return [];

        return tokenRequests
            .filter((request) => {
                // Filter by buy/sell
                if (filter === "buy" && request.requestType !== "buy") return false;
                if (filter === "sell" && request.requestType !== "sell") return false;

                // Filter by status
                if (statusFilter === "approve" && request.status !== "approve") return false;
                if (statusFilter === "rejected" && request.status !== "rejected") return false;

                return true;
            })
            .sort((a, b) => (a.name || "").localeCompare(b.name || ""));
    }, [tokenRequests, filter, statusFilter]);

    return (
        <div className="screen_wrapper">
            <div className="screen_header">
                <div style={{ display: "flex", alignItems: "center", gap: "20px", justifyContent: "center" }}>
                    <BackButton />
                    <h1 className="screen_heading">{`${statusFilter.toUpperCase() || "ALL"} Requests (${filteredTokenRequests.length} in total)`}</h1>
                </div>

                <div>
                    <div style={{ display: "flex", gap: "20px" }}>
                        <SelectInput
                            label={""}
                            value={statusFilter}
                            placeholder={"Please Select Status"}
                            setState={handleStatusFilterChange}
                        >
                            <SelectOption optionValue={""} optionText={"All"} />
                            <SelectOption optionValue={"approved"} optionText={"Approved"} />
                            <SelectOption optionValue={"rejected"} optionText={"Declined"} />
                        </SelectInput>
                        <SelectInput
                            label={""}
                            value={filter}
                            placeholder={"Please Select Filter"}
                            setState={handleFilterChange}
                        >
                            <SelectOption optionValue={"buy"} optionText={"Buy"} />
                            <SelectOption optionValue={"sell"} optionText={"Sell"} />
                        </SelectInput>

                        {/* Add status filter here */}

                    </div>
                </div>

                <div style={{ display: "flex", gap: "20px" }}>
                    {/* <CreateButton
                        screenTopicSingular={"Investment User"}
                        setShowCreateForm={setShowCreateForm}
                    /> */}
                </div>
            </div>

            <CRUDTable>
                <CRUDTableHeader>
                    <CRUDth align={"left"} th="Name" />
                    <CRUDth th="Nano Asset" />
                    <CRUDth th="Quantity" />
                    <CRUDth th="Total" />
                    <CRUDth th="Date of Request" />
                    <CRUDth th="Receipt" />
                    {/* {
                        statusFilter === "" && <CRUDth th="Type" />
                    } */}
                </CRUDTableHeader>
                <tbody>
                    {filteredTokenRequests.map((tokenRequest, indx) => (
                        <tr key={indx}>
                            <td>
                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <img
                                        style={{ height: "30px", width: "30px", borderRadius: "50%" }}
                                        src={
                                            tokenRequest.image
                                                ? process.env.VITE_REACT_APP_SPACES_URL + tokenRequest?.tokenInvestor?.image
                                                : `https://ui-avatars.com/api/?name=${tokenRequest?.tokenInvestor?.name}&background=random&length=1`
                                        }
                                        alt={tokenRequest.tokenInvestor?.name}
                                    />
                                    <p>{tokenRequest.tokenInvestor?.name}</p>
                                </div>
                            </td>
                            <ShortTextCell text={tokenRequest.token?.tokenName} />
                            <ShortTextCell text={tokenRequest?.quantity} />
                            <ShortTextCell text={takaFormatter(tokenRequest.totalAmount)} />
                            <ShortTextCell text={formatDate(tokenRequest.createdAt)} />
                            <td>
                                <div onClick={() => handleImageClick(tokenRequest.receipt)} className="flex justify-center items-center cursor-pointer">
                                    <img src={process.env.REACT_APP_SPACES_URL + tokenRequest?.receipt} alt="receipt" className="w-28 h-28" />
                                </div>
                            </td>
                            {/* <td className="action_buttons_wrapper">
                                <div className="action_buttons wc_investor_action_buttons">
                                </div>

                            </td> */}
                            {/* {
                                statusFilter === "" && <td>
                                    <div onClick={() => handleImageClick(tokenRequest.receipt)} className="flex justify-center items-center cursor-pointer">
                                        <p>{tokenRequest.requestType}</p>
                                    </div>
                                </td>
                            } */}
                        </tr>
                    ))}
                </tbody>
            </CRUDTable>
            {
                showReceiptSection &&
                <Modal
                    extraClass={"small"}
                    modalHeading={`View Receipt`}
                    setShowModalContent={setShowReceiptSection}
                >
                    <ViewReceipt imageSrc={process.env.REACT_APP_SPACES_URL + selectedReceipt} />
                </Modal>
            }
        </div>
    );

}
export default AllRequest;
